import React from 'react'
import './contact.css'

export const Contact = () => {
  return (
    <div className='contact'>
      <h3>Thanks for taking the time to reach out, how can we help you</h3>

      <div className="form">

        <div className="row">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input id='name' type="text" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input id='email' type="email" />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id='message' type="text" rows={10} cols={1} />
        </div>

        <div className="submit-btn">Submit</div>

      </div>
    </div>
  )
}
