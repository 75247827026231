import React from 'react'
import './collaboration.css'

export const Collaboration = ({title, color, link}) => {
  return (
    <div className='collaboration' >
        <a href={link} target="_blank" rel="noopener noreferrer" style={{color}}>{title}</a>
    </div>
  )
}
