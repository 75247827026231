import React from 'react'
import './template.css'

export const Template = ({title, description, image, project}) => {
  return (
    <div className='template'>
        <div className='template-image'>
            <img src={image} alt='project' />
        </div>
        <div className="template-text">
            <div className="title">
                {title}
            </div>
            <div className="description">
                {description}
            </div>
            <a href={project} target="_blank" rel="noopener noreferrer">
                <div className="button">
                    View Site
                </div>
            </a>
        </div>
    </div>
  )
}
