import React from 'react'
import './footer.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-text">
        Your partner in the cyber world
      </div>
      <div className="social-icons">
        <TwitterIcon/>
        <InstagramIcon/>
        <LinkedInIcon/>
        <MailOutlineIcon/>
      </div>
      <div className="copyright">
        Copyright NeutroSpot all rights reserved
      </div>
    </div>
  )
}
