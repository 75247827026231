import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Header, Footer} from './components'
import { Home } from './pages/home/Home';
import { Contact } from './pages/contact/Contact';

function App() {
  return (
    <BrowserRouter>
      <Header/>
          <Routes>
            <Route path='/contact' element={<Contact />}/>
            <Route path='/' element={<Home />}/>
          </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
