import React from 'react'
import BrushIcon from '@mui/icons-material/Brush';
import CodeIcon from '@mui/icons-material/Code';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import './home.css'

import NS_logo from './assets/ns-logo.png'  
import Devices_logo from './assets/devices.png'
import shop_picture from './assets/shop.png'
import blog_picture from './assets/blog.png'
import { Service } from '../../components/service/Service'
import { Template } from '../../components/template/Template';
import { Collaboration } from '../../components/Collaboration/Collaboration';
import { Testimonial } from '../../components/testimonials/Testimonial';

export const Home = () => {
  return (
    <div className='home'>
        <h3>Website and Mobile Application, Development </h3>
        <p> We aim to enhance the online presence and impact of business for greater customer satisfaction</p>

        <div className="avatar">
          <img src={NS_logo} alt="NeutroSpot Avatar" />
        </div>
        <div className="devices-avatar">
          <img src={Devices_logo} alt="devices" />
        </div>
        
        <div className="about">
          <h3>Hi there. Nice to meet you</h3>
          <p>Since beginning our journey to provide digital services to in 2019 , we've done remote work with agencies, consulted for startups, and collaborated with talented people to create digital products for both consumer and business use and we are looking forward to working with you too.</p>
        </div>

        <h3>Services</h3>
        <p>What we can do for you</p>

        <div className="services">
          <Service icon={<BrushIcon/>} title={'Design'} description={'We have a team of world class graphic designers ready to come up with the most compelling build of your ideas'} />
          <Service icon={<CodeIcon/>}  title={'Build'} description={'We can implement optimised and secure platforms for you using the latest and best practises'} />
          <Service icon={<MenuBookIcon/>} title={'Consult'} description={'If you\'re in need of guidance on how to create better engagement and growth in digital products, we have experienced personel to provide assistance'} />
        </div>

        <h3>Recent Work</h3>
        <p>Here are a few ready to go templates in our portfolio</p>

        <div className="recent-work">
          <Template title='Black and White blog' description='Simple minimalistic personal blog suitable for writers ' image={blog_picture} project="https://lighthearted-crisp-181e4d.netlify.app/" />
          <Template title='You Commerce' description='An online ecommerce website for you to set up an online shop and sell your goods' image={shop_picture} project='https://neutrospotdev.pythonanywhere.com' />
          {/* <Template title='Business Portfolio' description='A fully intergrated business portfolio website, that comes with email and whatsapp intergration for your customers to contact reach out ' image='https://cdn.pixabay.com/photo/2018/11/16/18/33/flat-3820024_960_720.png' project='' /> */}

        </div>

        <h3>We have collaborated with some awesome companies :</h3>
        <div className="collaborations">
          <Collaboration title={'Wota Consultancy'} color={'blue'} link={'https://www.wotaconsultancy.com/'}/>
          <Collaboration title={'Engineering Mastery'} color={'grey'} link={'https://engineeringmastery.co.zw'}/>
          
        </div>

        {/* <div className="about">
          <h3>Our Startup services and APIs </h3>
          <p>Over the years, we've used alot of software tools and services in different industries and verticals. Eventually, we decided that it would be a nice challenge to try designing and building our own.</p>
        </div>

        <div className="recent-work">

        </div> */}

        <h3>Testimonials</h3>
        <p>What people we've worked with have to say</p>

        <div className="testimonials">
          <Testimonial username='Wayne' comment="I appreciate the professionalism and diligance demonstrated by the team and they're client first approach" rating={4} />
        </div>
    </div>


  )
}
