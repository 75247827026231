import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'

export const Header = () => {
  return (
    <div className='header'>
        <Link to={'/'}>
            <div className="header-left">
                <div className="header-logo">

                </div>
                <div className="header-title">
                    NeutroSpot
                </div>
            </div> 
        </Link>
        
        <a href='https://wa.me/263780596739'> 
            <div className="header-right">
                <div className="contact-us">
                    Contact us
                </div>
            </div>
        </a>
    </div>
  )
}
