import React from 'react'

import './service.css'

export const Service = ({icon, title, description, other }) => {
  return (
    <div className='service'>
        <div className="icon-container">
          <div className="icon">
            {
              icon
            }
          </div>
        </div>
        <div className="title">
          {
            title
          }
        </div>
        <div className="description">
          {
            description
          }
        </div>
    </div>
  )
}
