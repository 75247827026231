import React from 'react'
import './testimonial.css'
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

export const Testimonial = ({username, comment, rating}) => {
  return (
    <div className='testimonial'>
      <div className="comment">
        {
          comment
        }
      </div>
      <div className="username">
        {username}
      </div>
      <div className="rating">
          {
            [...Array(rating)].map(() => <StarIcon/>)
          }
          {
            [...Array(5-rating)].map(() => <StarOutlineIcon/>)
          }
      </div>
    </div>
  )
}
